import { defineStore } from 'pinia'

const _getDefaultState = () => ({
  instance: {
    id: null,
    undiscountedGrandTotal: 0,
    grandTotal: 0,
    shippingTotal: null,
    shippingDestinationFull: '',
    taxTotal: 0,
    milesAndMoreData: null,
    products: [],
    vendors: [],
  },
  calculated: {
    itemCount: 0,
    productsByVendor: {},
    indexesByVendor: {},
    grandTotalFormatted: null,
    shippingTotalFormatted: '',
    valueOfGoods: 0,
    valueOfGoodsFormatted: '',
    taxTotalFormatted: '',
    discount: 0,
    formattedDiscount: ''
  },
  updated_at: null,
  updating: true,
})

export const useCartStore = defineStore('cart', {
  state: () => _getDefaultState(),
  getters: {
    cartId: ({ instance: { id } }) => id,
    lastUpdate: ({ updated_at }) => updated_at,
    cartInstance: ({ instance, calculated }) => ({ ...instance, ...calculated }),
    isUpdating: ({ updating }) => updating,
    vendors: ({ instance: { vendors } }) => vendors,
    vendorItems: ({ calculated: { productsByVendor } }) => (id) => productsByVendor[id],
    vendorIndex: ({ calculated: { indexesByVendor } }) => (id) => indexesByVendor[id],
    productInCart: ({ instance: { products } }) => (productId) => products.includes(productId),
    firstVendorWithMessages: ({ instance: { vendors } }) => vendors.filter(({ messages }) => messages.length)[0],
    hasOtherVendorsThan: ({ instance: { vendors } }) => vendorName => vendors.some(({ name }) => name !== vendorName),
    itemCount: ({ calculated: { itemCount } }) => itemCount,
    campaignItemCounts: ({ calculated: { campaignItemCounts } }) => campaignItemCounts,
    valueOfGoods: ({ calculated: { valueOfGoods } }) => valueOfGoods,
    discount: ({ calculated: { discount } }) => discount,
    formattedDiscount: ({ calculated: { formattedDiscount } }) => formattedDiscount,
    hasMessages: ({ firstVendorWithMessages }) => !!firstVendorWithMessages
  },
  actions: {
    async reset() {
      if (this.$router.currentRoute.name !== 'cart') return

      const { instance, calculated } = _getDefaultState()

      this.instance = instance
      this.calculated = calculated

      await this.refreshCart()
    },
    async setupState(data) {
      const { instance, calculated } = await _getMutationObject(data)
      this.instance = instance
      this.calculated = calculated
      this.updated_at = new Date()
    },
    async refreshCart() {
      this.updating = true

      const cartId = await _createOrGetCartInstance()
      const { ApiController, UtilityHelper, TrackingHelper } = await _getModules()
      try {
        const data = await ApiController.refreshCart(cartId)
        await this.setupState(data, cartId)

        TrackingHelper.sendUtmParams()
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at cart [refreshCart]')
      }

      this.updating = false
    },
    async addItem(productData) {
      this.updating = true
      const { productId, campaignId, qty, upsellId } = productData

      const {
        UtilityHelper,
        ApiController,
        PAGE_URL,
      } = await _getModules()
      try {
        const data = await ApiController.addItemToCart(await _createOrGetCartInstance(), {
          product_id: productId,
          campaign_id: campaignId,
          qty: qty,
          upsell_id: upsellId
        })
        await this.setupState(data)

        _addMessage('header_cart_add_to_cart_success_message', 'icon_svg_cart', PAGE_URL.CART)

        void _trackAddItem(productData)
      } catch (e) {
        _addMessage('header_cart_add_to_cart_failure_message', 'icon_svg_info')
        UtilityHelper.noticeNRError(e, 'Error at cart [addItemToCart] ')
      }

      this.updating = false
    },
    async updateItem({ item, qty, itemListId, index }) {
      this.updating = true

      const { ApiController, UtilityHelper } = await _getModules()

      try {
        const data = await ApiController.updateCartItem(await _createOrGetCartInstance(), {
          cartItemId: item.cart_item_id,
          qty
        })
        await this.setupState(data)

        _addMessage('new_cart_page_update_success', 'icon_svg_checkmark')
        void _trackUpdateItem(item, qty, item.qty, itemListId, index)
      } catch (e) {
        _addMessage('new_cart_page_update_fail', 'icon_svg_alert')
        UtilityHelper.noticeNRError(e, 'Error at cart [updateItem]')
      }
      this.updating = false
    },
    async removeItem({ item, itemListId, index }) {
      this.updating = true

      const { ApiController, UtilityHelper } = await _getModules()

      try {
        const data = await ApiController.removeCartItem(await _createOrGetCartInstance(), item.cart_item_id)
        await this.setupState(data)

        _addMessage('new_cart_page_remove_success', 'icon_svg_checkmark')
        void _trackRemoveItem(item, itemListId, index)
      } catch (e) {
        UtilityHelper.noticeNRError(e, 'Error at cart [removeItem]')
        _addMessage('new_cart_page_remove_fail', 'icon_svg_alert')
      }

      this.updating = false
    },
  }
})

const _addMessage = (messageKey, icon, url = null) => {
  setTimeout(async () => {
    const { MessageHelper } = await _getModules()
    MessageHelper.addMessage(messageKey, icon, url)
  }, 0)
}

const _getMutationObject = async (data) => {
  const {
    uuid,
    vendors,
    products,
    shipping_total: shippingTotal,
    discount_value: discount,
    value_of_goods: valueOfGoods,
    item_count: itemCount,
    undiscounted_grand_total: undiscountedGrandTotal,
    grand_total: grandTotal,
    miles_and_more: milesAndMoreData,
    tax_total: taxTotal,
    shipping_destination_full: shippingDestinationFull
  } = data

  const { TranslationsHelper, fetchProductWithIndex, matchItemWithProduct } = await _getModules()

  const grandTotalFormatted = TranslationsHelper.formatCurrency(grandTotal)
  const shippingTotalFormatted = TranslationsHelper.formatCurrency(shippingTotal)
  const valueOfGoodsFormatted = TranslationsHelper.formatCurrency(valueOfGoods)
  const taxTotalFormatted = TranslationsHelper.formatCurrency(taxTotal)
  const formattedDiscount = TranslationsHelper.formatCurrency(discount * -1)

  const indexedProducts = await fetchProductWithIndex(vendors.reduce((items, vendor) => [...items, ...vendor.items], []))
  const productsByVendor = vendors.reduce((c, { vendor_id: id, items }) => ({
    ...c,
    [id]: matchItemWithProduct(
      (items ?? []).reduce((c, item) => item.is_upsell ? c : [...c, item], []),
      indexedProducts,
      { endpoint: 'cart' }
    )
  }), {})

  const indexesByVendor = {}
  let start = 0
  for (const [id, items] of Object.entries(productsByVendor)) {
    indexesByVendor[id] = start
    start += items.length
  }

  return {
    instance: {
      id: uuid,
      undiscountedGrandTotal,
      grandTotal,
      shippingTotal,
      shippingDestinationFull,
      taxTotal,
      milesAndMoreData,
      products,
      vendors
    },
    calculated: {
      productsByVendor,
      indexesByVendor,
      itemCount,
      grandTotalFormatted,
      shippingTotalFormatted,
      valueOfGoods,
      valueOfGoodsFormatted,
      taxTotalFormatted,
      discount,
      formattedDiscount
    }
  }
}

const _createOrGetCartInstance = async () => {
  const { CookieHelper, UtilityHelper, domain } = await _getModules()
  let id = CookieHelper.getCookie('cartUuid')
  if (!id || id === 'null') {
    id = UtilityHelper.uuid()
    CookieHelper.setCookie('cartUuid', id, 365, domain)
  }
  return id
}

const _trackAddItem = async (data) => {
  const trackingData = await _getTrackingData(data)
  const { default: GA4Controller } = await import('@/services/analytics/GA4Controller')
  await GA4Controller.pushAddToCartEvent(trackingData)
}

const _trackUpdateItem = async (item, newQty, oldQty, itemListId, index) => {
  const { GtmCartPage } = await _getModules()
  GtmCartPage.changeQty(item, { newQty, oldQty, itemListId, index })
}

const _trackRemoveItem = async (item, itemListId, index) => {
  if (!itemListId) return
  const { GtmCartPage, AnalyticsItemFactory } = await _getModules()
  const analyticsItem = AnalyticsItemFactory.getAnalyticsItem({ item, itemListId, quantity: item.qty, index })
  GtmCartPage.deleteCartItem({ analyticsItem, itemListId })
}

const _getTrackingData = async ({
                                  productId,
                                  name,
                                  campaignId,
                                  manufacturer,
                                  variant,
                                  price,
                                  originalPrice,
                                  qty,
                                  itemListId,
                                  index
                                }) => {
  const { EcProductFactory, AnalyticsItemFactory } = await _getModules()

  const ecProduct = EcProductFactory.getEcProduct(productId, name, campaignId, manufacturer, variant, price, originalPrice, qty)
  const analyticsItem = AnalyticsItemFactory.getAnalyticsItem({ item: ecProduct, itemListId, index })

  return { ecProduct, analyticsItem, itemListId }
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { fetchProductWithIndex, matchItemWithProduct },
      { PAGE_URL, PRODUCT_TYPES },
      { default: TranslationsHelper },
      { default: UtilityHelper },
      { default: ApiController },
      { default: TrackingHelper },
      { default: EcProductFactory },
      { default: AnalyticsItemFactory },
      { default: MessageHelper },
      { default: CookieHelper },
      { default: { domain } },
      { default: GtmCartPage }
    ] = await Promise.all([
      await import('@/services/helpers/BulkProductMatcher'),
      await import('@/constants/GlobalConstants'),
      await import('@/services/helpers/TranslationsHelper'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/modules/tag_manager/EcProductFactory'),
      await import('@/modules/tag_manager/AnalyticsItemFactory'),
      await import('@/services/helpers/MessageHelper'),
      await import('@/services/helpers/CookieHelper'),
      await import('@/services/SiteConfig'),
      await import('@/services/analytics/handlers/GtmCartPage')
    ])
    cache = {
      fetchProductWithIndex,
      matchItemWithProduct,
      PAGE_URL,
      PRODUCT_TYPES,
      TranslationsHelper,
      UtilityHelper,
      ApiController,
      TrackingHelper,
      EcProductFactory,
      AnalyticsItemFactory,
      MessageHelper,
      CookieHelper,
      domain,
      GtmCartPage
    }
    return cache
  }
})()
